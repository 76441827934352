<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pt-2">
        <v-card-text>
          <p>Here you can view and restore deleted discounts, and also view changes that were made.</p>
        </v-card-text>
      </v-card>

      <v-card class="pt-0 pl-5 elevation-0">

        <v-tabs v-model="itemTab" @change="itemTabChanged">
          <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="itemTab">
          <v-tab-item>
            <v-row class="inner-content">
              <v-col>
                <DiscountsDeletedTable v-if="itemTab == 0" />
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row class="inner-content">
              <v-col>
                <DiscountsChangedTable v-if="itemTab == 1" />
              </v-col>
            </v-row>
          </v-tab-item>

        </v-tabs-items>

      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DiscountsHistory',

  data () {
    return {
      itemTab: null,
      items: ['Deleted', 'Changed'],
      lsItemTab: 'discountHistory_tab',
    }
  },

  components: {
    DiscountsChangedTable: () => import('@/components/tables/DiscountsChangedTable.vue'),
    DiscountsDeletedTable: () => import('@/components/tables/DiscountsDeletedTable.vue'),
  },

  methods: {
    itemTabChanged () {
      localStorage.setItem(this.lsItemTab, JSON.stringify(this.itemTab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsItemTab)) {
      this.itemTab = JSON.parse(localStorage.getItem(this.lsItemTab))
    }
  },
}
</script>
